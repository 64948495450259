<template>
<div>
    <v-container>
        <pdf src="pdf/assist/Ficha Protección Assist.pdf"></pdf>
    </v-container>
</div>
</template>

<script>
import pdf from 'vue-pdf';
export default {
    name: "Resumen",
    components: {
        pdf
    }
}
</script>
